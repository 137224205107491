<template>
    <div id="langSelector" class="intro-x dropdown w-6 h-6">
      <div
        class="dropdown-toggle w-6 h-6 rounded-full overflow-hidden shadow-lg image-fit zoom-in"
        role="button"
        aria-expanded="false"
      >
        <img 
          v-if="this.$i18n.locale == 'ru'"
          src="@/assets/images/ru.png"
        />
        <img 
          v-else
          src="@/assets/images/en.png"
        />
      </div>

      <div class="dropdown-menu w-56">
        <div
          class="dropdown-menu__content box bg-theme-26 dark:bg-dark-6 text-white"
        >
          <div class="p-2">
            <button
              type="button"
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md w-full"
              v-on:click="changeLocale('ru')"
            >
              <img style="width: 24px; margin: 10px"
                src="@/assets/images/ru.png"
              /> Русский
            </button>
            <button
              type="button"
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-theme-1 dark:hover:bg-dark-3 rounded-md w-full"
              v-on:click="changeLocale('en')"
            >
              <img style="width: 24px; margin: 10px"
                src="@/assets/images/en.png"
              /> English
            </button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'LocaleSelector',
    methods: {
        changeLocale: function(locale) {
            const langSelector = document.getElementById('langSelector')
            langSelector.click()
            this.$i18n.locale = locale
        }
    }
})
</script>
