<template>
    
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Broadcast',
    mounted: function() {
        this.$store.commit('updateTopBarTitle', this.$i18n.t('menu.wallet'))
    }
})
</script>
