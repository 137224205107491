import Toastify from "toastify-js";


class Toast {
    constructor() {
    }

    success(message) {
        const html = new DOMParser().parseFromString(`
            <div id="notification" class="toastify-content flex">
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle text-theme-9"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                <div class="ml-4 mr-4">
                    <div class="font-medium">${message}</div>
                </div>
            </div>`, 'text/html')
            this.show(null, html)
    }

    error(message) {
        const html = new DOMParser().parseFromString(`
            <div id="notification" class="toastify-content flex">
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-circle block mx-auto block mx-auto text-red-600"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg>
                <div class="ml-4 mr-4">
                    <div class="font-medium">${message}</div>
                </div>
            </div>`, 'text/html')
        this.show(null, html)
    }

    show(message, html = null) {
        if (message) {
            html = new DOMParser().parseFromString(`
                <div id="notification" class="toastify-content flex flex-col sm:flex-row">
                    <div class="font-medium">${message}</div>
                </div>`, 'text/html')
        }
        Toastify({
            node: html.getElementById('notification'),
            duration: 3000,
            newWindow: true,
            close: true,
            gravity: "top",
            position: "right",
            stopOnFocus: true,
        }).showToast();
    }
}

const install = app => {
    app.config.globalProperties.$toast = new Toast()
}
  
export { install as default }