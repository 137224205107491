import { createStore } from 'vuex'
import init from './config'

const store = createStore({
  state: {
    topBarTitle: '',
    darkMode: true
  },
  mutations: {
    updateTopBarTitle(state, title) {
      state.topBarTitle = title
    },
    setDarkMode(state, { darkMode }) {
      state.darkMode = darkMode
    }
  },
  actions: {
    setDarkMode({ commit }, darkMode) {
      commit('setDarkMode', { darkMode })
    }
  },
  modules: {
  }
})


init(store)


export default store
