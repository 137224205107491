import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import messages from '@/i18n/messages'
import utils from './utils'
import App from './App.vue'
import router from './router'
import store from './store'
import globalComponents from '@/components/global'
import './libs'

// SASS Theme
import './assets/sass/app.scss'


const initApp = function() {
    const i18n = createI18n({
        fallbackLocale: 'en',
        locale: navigator.language == 'ru' ? 'ru' : 'en',
        messages: messages
    })

    const app = createApp(App).use(i18n).use(store).use(router)
    utils(app)
    globalComponents(app)

    app.mount('#app')
}
  
store.dispatch('common/env/init').then(() => {
    const wallet = store.getters['common/wallet/lastWallet']
    if (wallet) {
        store.dispatch('common/wallet/unlockWallet', { name: 'wallet', password: 'wallet' }).then(() => {
            initApp()
        }).catch(() => {
            initApp()
        })
    } else {
        initApp()
    }
})


