<template>
    <div class="intro-y mt-8 box">
        <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
        <h2 class="font-medium text-base mr-auto">{{$t('views.login.createWallet')}}</h2>
        </div>
        <div class="p-5 text-center" v-if="!wallet.visible">
            <button class="btn btn-primary py-3 px-4 w-full align-top" v-on:click="generateWallet">
                {{$t('views.login.generateSeedPhrase')}}
            </button>
        </div>
        <div class="p-5" v-if="wallet.visible">
            <label class="form-label">{{$t('views.login.generatedAddress')}}</label>
            <div class="input-group mt-2">
                <input type="text" class="form-control" v-model="wallet.address" readonly />
                <div class="input-group-text"><button type="button" v-on:click="copyToClipboard(wallet.address)"><CopyIcon/></button></div>
            </div>
            <label class="form-label mt-10">{{$t('views.login.generatedSeedPhrase')}}</label>
            <div class="input-group mt-2">
                <textarea class="form-control" rows="2" v-model="wallet.seed" readonly></textarea>
                <div class="input-group-text flex items-center"><button type="button" v-on:click="copyToClipboard(wallet.seed)"><CopyIcon/></button></div>
            </div>
        </div>
    </div>
</template>

<script>
import * as bip39 from 'bip39'
import { stringToPath } from '@cosmjs/crypto'
import { DirectSecp256k1HdWallet } from '@cosmjs/proto-signing'
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'CreateWallet',
    data: function() {
        return {
            wallet: {
                visible: false,
                address: '',
                seed: ''
            }
        }
    },
    methods: {
        generateWallet: async function() {
            const mnemonic = bip39.generateMnemonic(256)
            const prefix = this.$store.state.common.env.addrPrefix

            const accountSigner = await DirectSecp256k1HdWallet.fromMnemonic(mnemonic, {
				HDPaths: [stringToPath("m/44'/118'/0'/0/0")],
				prefix,
			})
            const [firstAccount] = await accountSigner.getAccounts()
            const account = { address: firstAccount.address, pathIncrement: 0 }

            this.wallet.address = account.address
            this.wallet.seed = mnemonic

            this.wallet.visible = true
        },
        copyToClipboard: async function(val) {
            await navigator.clipboard.writeText(val)
            this.$toast.success(this.$i18n.t('common.copied'))
        }
    }
})
</script>
