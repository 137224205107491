import { txClient, queryClient, MissingWalletError } from './module';
// @ts-ignore
import { SpVuexError } from '@starport/vuex';
import { Bar } from "./module/types/depository/v1/bar";
import { Depository } from "./module/types/depository/v1/depository";
import { DepositorySetOperatorProposal } from "./module/types/depository/v1/depository";
import { Reserve } from "./module/types/depository/v1/reserve";
export { Bar, Depository, DepositorySetOperatorProposal, Reserve };
async function initTxClient(vuexGetters) {
    return await txClient(vuexGetters['common/wallet/signer'], {
        addr: vuexGetters['common/env/apiTendermint']
    });
}
async function initQueryClient(vuexGetters) {
    return await queryClient({
        addr: vuexGetters['common/env/apiCosmos']
    });
}
function mergeResults(value, next_values) {
    for (let prop of Object.keys(next_values)) {
        if (Array.isArray(next_values[prop])) {
            value[prop] = [...value[prop], ...next_values[prop]];
        }
        else {
            value[prop] = next_values[prop];
        }
    }
    return value;
}
function getStructure(template) {
    let structure = { fields: [] };
    for (const [key, value] of Object.entries(template)) {
        let field = {};
        field.name = key;
        field.type = typeof value;
        structure.fields.push(field);
    }
    return structure;
}
const getDefaultState = () => {
    return {
        Depository: {},
        Depositories: {},
        Bar: {},
        Bars: {},
        Reserve: {},
        Reserves: {},
        _Structure: {
            Bar: getStructure(Bar.fromPartial({})),
            Depository: getStructure(Depository.fromPartial({})),
            DepositorySetOperatorProposal: getStructure(DepositorySetOperatorProposal.fromPartial({})),
            Reserve: getStructure(Reserve.fromPartial({})),
        },
        _Subscriptions: new Set(),
    };
};
// initial state
const state = getDefaultState();
export default {
    namespaced: true,
    state,
    mutations: {
        RESET_STATE(state) {
            Object.assign(state, getDefaultState());
        },
        QUERY(state, { query, key, value }) {
            state[query][JSON.stringify(key)] = value;
        },
        SUBSCRIBE(state, subscription) {
            state._Subscriptions.add(subscription);
        },
        UNSUBSCRIBE(state, subscription) {
            state._Subscriptions.delete(subscription);
        }
    },
    getters: {
        getDepository: (state) => (params = { params: {} }) => {
            if (!params.query) {
                params.query = null;
            }
            return state.Depository[JSON.stringify(params)] ?? {};
        },
        getDepositories: (state) => (params = { params: {} }) => {
            if (!params.query) {
                params.query = null;
            }
            return state.Depositories[JSON.stringify(params)] ?? {};
        },
        getBar: (state) => (params = { params: {} }) => {
            if (!params.query) {
                params.query = null;
            }
            return state.Bar[JSON.stringify(params)] ?? {};
        },
        getBars: (state) => (params = { params: {} }) => {
            if (!params.query) {
                params.query = null;
            }
            return state.Bars[JSON.stringify(params)] ?? {};
        },
        getReserve: (state) => (params = { params: {} }) => {
            if (!params.query) {
                params.query = null;
            }
            return state.Reserve[JSON.stringify(params)] ?? {};
        },
        getReserves: (state) => (params = { params: {} }) => {
            if (!params.query) {
                params.query = null;
            }
            return state.Reserves[JSON.stringify(params)] ?? {};
        },
        getTypeStructure: (state) => (type) => {
            return state._Structure[type].fields;
        }
    },
    actions: {
        init({ dispatch, rootGetters }) {
            console.log('Vuex module: metdeal.cryptounce.depository.v1 initialized!');
            if (rootGetters['common/env/client']) {
                rootGetters['common/env/client'].on('newblock', () => {
                    dispatch('StoreUpdate');
                });
            }
        },
        resetState({ commit }) {
            commit('RESET_STATE');
        },
        unsubscribe({ commit }, subscription) {
            commit('UNSUBSCRIBE', subscription);
        },
        async StoreUpdate({ state, dispatch }) {
            state._Subscriptions.forEach(async (subscription) => {
                try {
                    await dispatch(subscription.action, subscription.payload);
                }
                catch (e) {
                    throw new SpVuexError('Subscriptions: ' + e.message);
                }
            });
        },
        async QueryDepository({ commit, rootGetters, getters }, { options: { subscribe, all } = { subscribe: false, all: false }, params: { ...key }, query = null }) {
            try {
                const queryClient = await initQueryClient(rootGetters);
                let value = (await queryClient.queryDepository(key.denom)).data;
                commit('QUERY', { query: 'Depository', key: { params: { ...key }, query }, value });
                if (subscribe)
                    commit('SUBSCRIBE', { action: 'QueryDepository', payload: { options: { all }, params: { ...key }, query } });
                return getters['getDepository']({ params: { ...key }, query }) ?? {};
            }
            catch (e) {
                throw new SpVuexError('QueryClient:QueryDepository', 'API Node Unavailable. Could not perform query: ' + e.message);
            }
        },
        async QueryDepositories({ commit, rootGetters, getters }, { options: { subscribe, all } = { subscribe: false, all: false }, params: { ...key }, query = null }) {
            try {
                const queryClient = await initQueryClient(rootGetters);
                let value = (await queryClient.queryDepositories(query)).data;
                while (all && value.pagination && value.pagination.nextKey != null) {
                    let next_values = (await queryClient.queryDepositories({ ...query, 'pagination.key': value.pagination.nextKey })).data;
                    value = mergeResults(value, next_values);
                }
                commit('QUERY', { query: 'Depositories', key: { params: { ...key }, query }, value });
                if (subscribe)
                    commit('SUBSCRIBE', { action: 'QueryDepositories', payload: { options: { all }, params: { ...key }, query } });
                return getters['getDepositories']({ params: { ...key }, query }) ?? {};
            }
            catch (e) {
                throw new SpVuexError('QueryClient:QueryDepositories', 'API Node Unavailable. Could not perform query: ' + e.message);
            }
        },
        async QueryBar({ commit, rootGetters, getters }, { options: { subscribe, all } = { subscribe: false, all: false }, params: { ...key }, query = null }) {
            try {
                const queryClient = await initQueryClient(rootGetters);
                let value = (await queryClient.queryBar(key.number, key.brand)).data;
                commit('QUERY', { query: 'Bar', key: { params: { ...key }, query }, value });
                if (subscribe)
                    commit('SUBSCRIBE', { action: 'QueryBar', payload: { options: { all }, params: { ...key }, query } });
                return getters['getBar']({ params: { ...key }, query }) ?? {};
            }
            catch (e) {
                throw new SpVuexError('QueryClient:QueryBar', 'API Node Unavailable. Could not perform query: ' + e.message);
            }
        },
        async QueryBars({ commit, rootGetters, getters }, { options: { subscribe, all } = { subscribe: false, all: false }, params: { ...key }, query = null }) {
            try {
                const queryClient = await initQueryClient(rootGetters);
                let value = (await queryClient.queryBars(query)).data;
                while (all && value.pagination && value.pagination.nextKey != null) {
                    let next_values = (await queryClient.queryBars({ ...query, 'pagination.key': value.pagination.nextKey })).data;
                    value = mergeResults(value, next_values);
                }
                commit('QUERY', { query: 'Bars', key: { params: { ...key }, query }, value });
                if (subscribe)
                    commit('SUBSCRIBE', { action: 'QueryBars', payload: { options: { all }, params: { ...key }, query } });
                return getters['getBars']({ params: { ...key }, query }) ?? {};
            }
            catch (e) {
                throw new SpVuexError('QueryClient:QueryBars', 'API Node Unavailable. Could not perform query: ' + e.message);
            }
        },
        async QueryReserve({ commit, rootGetters, getters }, { options: { subscribe, all } = { subscribe: false, all: false }, params: { ...key }, query = null }) {
            try {
                const queryClient = await initQueryClient(rootGetters);
                let value = (await queryClient.queryReserve(key.denom)).data;
                commit('QUERY', { query: 'Reserve', key: { params: { ...key }, query }, value });
                if (subscribe)
                    commit('SUBSCRIBE', { action: 'QueryReserve', payload: { options: { all }, params: { ...key }, query } });
                return getters['getReserve']({ params: { ...key }, query }) ?? {};
            }
            catch (e) {
                throw new SpVuexError('QueryClient:QueryReserve', 'API Node Unavailable. Could not perform query: ' + e.message);
            }
        },
        async QueryReserves({ commit, rootGetters, getters }, { options: { subscribe, all } = { subscribe: false, all: false }, params: { ...key }, query = null }) {
            try {
                const queryClient = await initQueryClient(rootGetters);
                let value = (await queryClient.queryReserves(query)).data;
                while (all && value.pagination && value.pagination.nextKey != null) {
                    let next_values = (await queryClient.queryReserves({ ...query, 'pagination.key': value.pagination.nextKey })).data;
                    value = mergeResults(value, next_values);
                }
                commit('QUERY', { query: 'Reserves', key: { params: { ...key }, query }, value });
                if (subscribe)
                    commit('SUBSCRIBE', { action: 'QueryReserves', payload: { options: { all }, params: { ...key }, query } });
                return getters['getReserves']({ params: { ...key }, query }) ?? {};
            }
            catch (e) {
                throw new SpVuexError('QueryClient:QueryReserves', 'API Node Unavailable. Could not perform query: ' + e.message);
            }
        },
        async sendMsgDepositBar({ rootGetters }, { value, fee = [], memo = '', gas = '200000' }) {
            try {
                const txClient = await initTxClient(rootGetters);
                const msg = await txClient.msgDepositBar(value);
                const result = await txClient.signAndBroadcast([msg], { fee: { amount: fee,
                        gas: gas }, memo });
                return result;
            }
            catch (e) {
                if (e == MissingWalletError) {
                    throw new SpVuexError('TxClient:MsgDepositBar:Init', 'Could not initialize signing client. Wallet is required.');
                }
                else {
                    throw new SpVuexError('TxClient:MsgDepositBar:Send', 'Could not broadcast Tx: ' + e.message);
                }
            }
        },
        async sendMsgDepositBarSimulate({ rootGetters }, { value, fee = [], memo = '' }) {
            try {
                const txClient = await initTxClient(rootGetters);
                const msg = await txClient.msgDepositBar(value);
                const result = await txClient.simulate([msg], { fee: { amount: fee,
                        gas: "200000" }, memo });
                return result;
            }
            catch (e) {
                if (e == MissingWalletError) {
                    throw new SpVuexError('TxClient:MsgDepositBar:Init', 'Could not initialize signing client. Wallet is required.');
                }
                else {
                    throw new SpVuexError('TxClient:MsgDepositBar:Send', 'Could not broadcast Tx: ' + e.message);
                }
            }
        },
        async sendMsgBurn({ rootGetters }, { value, fee = [], memo = '', gas = '200000' }) {
            try {
                const txClient = await initTxClient(rootGetters);
                const msg = await txClient.msgBurn(value);
                const result = await txClient.signAndBroadcast([msg], { fee: { amount: fee,
                        gas: gas }, memo });
                return result;
            }
            catch (e) {
                if (e == MissingWalletError) {
                    throw new SpVuexError('TxClient:MsgBurn:Init', 'Could not initialize signing client. Wallet is required.');
                }
                else {
                    throw new SpVuexError('TxClient:MsgBurn:Send', 'Could not broadcast Tx: ' + e.message);
                }
            }
        },
        async sendMsgBurnSimulate({ rootGetters }, { value, fee = [], memo = '' }) {
            try {
                const txClient = await initTxClient(rootGetters);
                const msg = await txClient.msgBurn(value);
                const result = await txClient.simulate([msg], { fee: { amount: fee,
                        gas: "200000" }, memo });
                return result;
            }
            catch (e) {
                if (e == MissingWalletError) {
                    throw new SpVuexError('TxClient:MsgBurn:Init', 'Could not initialize signing client. Wallet is required.');
                }
                else {
                    throw new SpVuexError('TxClient:MsgBurn:Send', 'Could not broadcast Tx: ' + e.message);
                }
            }
        },
        async sendMsgWithdrawBar({ rootGetters }, { value, fee = [], memo = '', gas = '200000' }) {
            try {
                const txClient = await initTxClient(rootGetters);
                const msg = await txClient.msgWithdrawBar(value);
                const result = await txClient.signAndBroadcast([msg], { fee: { amount: fee,
                        gas: gas }, memo });
                return result;
            }
            catch (e) {
                if (e == MissingWalletError) {
                    throw new SpVuexError('TxClient:MsgWithdrawBar:Init', 'Could not initialize signing client. Wallet is required.');
                }
                else {
                    throw new SpVuexError('TxClient:MsgWithdrawBar:Send', 'Could not broadcast Tx: ' + e.message);
                }
            }
        },
        async sendMsgWithdrawBarSimulate({ rootGetters }, { value, fee = [], memo = '' }) {
            try {
                const txClient = await initTxClient(rootGetters);
                const msg = await txClient.msgWithdrawBar(value);
                const result = await txClient.simulate([msg], { fee: { amount: fee,
                        gas: "200000" }, memo });
                return result;
            }
            catch (e) {
                if (e == MissingWalletError) {
                    throw new SpVuexError('TxClient:MsgWithdrawBar:Init', 'Could not initialize signing client. Wallet is required.');
                }
                else {
                    throw new SpVuexError('TxClient:MsgWithdrawBar:Send', 'Could not broadcast Tx: ' + e.message);
                }
            }
        },
        async sendMsgMint({ rootGetters }, { value, fee = [], memo = '', gas = '200000' }) {
            try {
                const txClient = await initTxClient(rootGetters);
                const msg = await txClient.msgMint(value);
                const result = await txClient.signAndBroadcast([msg], { fee: { amount: fee,
                        gas: gas }, memo });
                return result;
            }
            catch (e) {
                if (e == MissingWalletError) {
                    throw new SpVuexError('TxClient:MsgMint:Init', 'Could not initialize signing client. Wallet is required.');
                }
                else {
                    throw new SpVuexError('TxClient:MsgMint:Send', 'Could not broadcast Tx: ' + e.message);
                }
            }
        },
        async sendMsgMintSimulate({ rootGetters }, { value, fee = [], memo = '' }) {
            try {
                const txClient = await initTxClient(rootGetters);
                const msg = await txClient.msgMint(value);
                const result = await txClient.simulate([msg], { fee: { amount: fee,
                        gas: "200000" }, memo });
                return result;
            }
            catch (e) {
                if (e == MissingWalletError) {
                    throw new SpVuexError('TxClient:MsgMint:Init', 'Could not initialize signing client. Wallet is required.');
                }
                else {
                    throw new SpVuexError('TxClient:MsgMint:Send', 'Could not broadcast Tx: ' + e.message);
                }
            }
        },
        async MsgDepositBar({ rootGetters }, { value }) {
            try {
                const txClient = await initTxClient(rootGetters);
                const msg = await txClient.msgDepositBar(value);
                return msg;
            }
            catch (e) {
                if (e == MissingWalletError) {
                    throw new SpVuexError('TxClient:MsgDepositBar:Init', 'Could not initialize signing client. Wallet is required.');
                }
                else {
                    throw new SpVuexError('TxClient:MsgDepositBar:Create', 'Could not create message: ' + e.message);
                }
            }
        },
        async MsgBurn({ rootGetters }, { value }) {
            try {
                const txClient = await initTxClient(rootGetters);
                const msg = await txClient.msgBurn(value);
                return msg;
            }
            catch (e) {
                if (e == MissingWalletError) {
                    throw new SpVuexError('TxClient:MsgBurn:Init', 'Could not initialize signing client. Wallet is required.');
                }
                else {
                    throw new SpVuexError('TxClient:MsgBurn:Create', 'Could not create message: ' + e.message);
                }
            }
        },
        async MsgWithdrawBar({ rootGetters }, { value }) {
            try {
                const txClient = await initTxClient(rootGetters);
                const msg = await txClient.msgWithdrawBar(value);
                return msg;
            }
            catch (e) {
                if (e == MissingWalletError) {
                    throw new SpVuexError('TxClient:MsgWithdrawBar:Init', 'Could not initialize signing client. Wallet is required.');
                }
                else {
                    throw new SpVuexError('TxClient:MsgWithdrawBar:Create', 'Could not create message: ' + e.message);
                }
            }
        },
        async MsgMint({ rootGetters }, { value }) {
            try {
                const txClient = await initTxClient(rootGetters);
                const msg = await txClient.msgMint(value);
                return msg;
            }
            catch (e) {
                if (e == MissingWalletError) {
                    throw new SpVuexError('TxClient:MsgMint:Init', 'Could not initialize signing client. Wallet is required.');
                }
                else {
                    throw new SpVuexError('TxClient:MsgMint:Create', 'Could not create message: ' + e.message);
                }
            }
        },
    }
};
