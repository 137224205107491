import { createRouter, createWebHistory } from 'vue-router'
import Login from '@/views/Login.vue'
import Broadcast from '@/views/Broadcast.vue'
import store from '@/store'

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/broadcast',
    name: 'broadcast',
    component: Broadcast
  },
  {
    path: '/',
    name: 'dashboard',
    redirect: 'wallet',
    component: () => import('@/components/layout/Main.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'wallet',
        name: 'dashboard-wallet',
        component: () => import('@/views/Wallet.vue')
      },
      {
        path: 'bars',
        name: 'dashboard-bars',
        component: () => import('@/views/Bars.vue')
      },
      {
        path: 'emission',
        name: 'dashboard-emission',
        component: () => import('@/views/Emission.vue')
      },
      {
        path: 'delegation',
        name: 'dashboard-delegation',
        component: () => import('@/views/Delegation.vue')
      },
      {
        path: 'proposals',
        name: 'dashboard-proposals',
        component: () => import('@/views/Proposals.vue')
      },
      {
        path: 'settings',
        name: 'dashboard-settings',
        component: () => import('@/views/Settings.vue')
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['common/wallet/loggedIn']) {
      next()
      return
    }
    next('/login')
  } else if (to.matched.some(record => record.name === 'login')) {
    if (store.getters['common/wallet/loggedIn']) {
      next('/')
      return
    }
    next()
  } else {
    next() 
  }
})

export default router
