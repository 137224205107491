<template>
    <div class="intro-y mt-8 box">
        <div class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5">
        <h2 class="font-medium text-base mr-auto">{{$t('views.login.loginWithSeedPhrase')}}</h2>
        </div>
        <div class="p-5 border-b border-gray-200 dark:border-dark-5">
        <input
            type="text"
            class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
            :placeholder="$t('views.login.yourSeedPhrase')"
            v-model="seedPhrase"
            :class="{ 'border-theme-6': validations.seedPhrase }"
        />
        <div class="text-theme-6 mt-2" v-if="validations.seedPhrase">{{ validations.seedPhrase }}</div>
        </div>
        <div class="p-5 text-center">
        <button class="btn btn-primary py-3 px-4 w-full align-top" v-on:click="signIn">
            {{$t('views.login.signIn')}}
        </button>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

const bip39 = require('bip39')

export default defineComponent({
    name: 'SeedPhraseLogin',
    data: function() {
        return {
            seedPhrase: '',
            validations: {
                seedPhrase: ''
            }
        }
    },
    methods: {
        validate: function() {
            this.validations.seedPhrase = ''
            if (!bip39.validateMnemonic(this.seedPhrase)) {
                this.validations.seedPhrase = this.$i18n.t('validations.invalidSeedPhrase')
            }
            return !this.validations.seedPhrase
        },
        signIn: async function() {
            localStorage.clear()

            if (this.validate()) {
                await this.$store.dispatch('common/wallet/createWalletWithMnemonic', {
                    name: 'wallet',
                    mnemonic: this.seedPhrase,
                    password: 'wallet',
                    prefix: this.$store.state.common.env.addrPrefix,
                })

                this.$router.push('/')
            }
        }
    }
})
</script>
