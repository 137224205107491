<template>
  <div>
    <!-- <DarkModeSwitcher /> -->
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="-intro-x flex items-center pt-5">
            <img
              alt="TechnoGold"
              class="w-6"
              src="@/assets/images/logo.svg"
            />
            <span class="text-white text-lg ml-3">
              Techno<span class="font-medium">Gold</span>
            </span>
          </a>
          <div class="my-auto">
            <img
              alt="TechnoGold"
              class="-intro-x w-1/2 -mt-16"
              src="@/assets/images/illustration.svg"
            />
            <div class="-intro-x text-white font-medium text-4xl leading-tight mt-10" v-html="$t('views.login.title')">
            </div>
            <div
              class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500"
            >
              {{$t('views.login.description')}}
            </div>
          </div>
        </div>
        <!-- END: Login Info -->

        <!-- BEGIN: Login Form -->
        <div class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full "
          >
            <div class="intro-x dropdown w-6 h-6 ml-auto">
              <LocaleSelector />           
            </div>

            <SeedPhraseLogin />
            <CreateWallet />

            <div
              class="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left"
            >
              {{$t('views.login.agreements')}} <br />
              <a class="text-theme-1 dark:text-theme-10" href=""
                >Terms and Conditions</a
              >
              &
              <a class="text-theme-1 dark:text-theme-10" href=""
                >Privacy Policy</a
              >
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import SeedPhraseLogin from '@/components/login/SeedPhraseLogin.vue'
import CreateWallet from '@/components/login/CreateWallet.vue'
import LocaleSelector from '@/components/common/LocaleSelector.vue'

export default defineComponent({
  name: 'Login',
  components: {
    SeedPhraseLogin,
    CreateWallet,
    LocaleSelector
  },
  created() {
    document.title = this.$i18n.t('title.home')
  },
  setup() {
    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    })
  },
  methods: {
    changeLocale: function(locale) {
      
    }
  }
})
</script>
