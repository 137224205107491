<template>
  <router-view/>
</template>

<script>
import { defineComponent } from 'vue'
import Constants from '@/utils/constants'


export default defineComponent({
  name: 'App',
  components: { },
  computed: { },
  beforeCreate: async function() {
    cash('html').addClass('dark')
    await this.$store.dispatch('cosmos.bank.v1beta1/QueryDenomsMetadata', { options: {} }, {})
    await this.$store.dispatch('metdeal.cryptounce.depository.v1/QueryDepository', { options: {}, params: {denom: Constants.DEFAULT_CURRENCY} })
  }
})
</script>
