const messages = {
    en: {
        common: {
            copied: 'Copied!',
            errorNotification: 'Error has occuried!',
            fee: 'Fee'
        },
        title: {
            home: 'TechnoGold - Dashboard'
        },
        menu: {
            wallet: 'Wallet',
            bars: 'Bars',
            emission: 'Emission',
            delegation: 'Delegation',
            proposals: 'Proposals',
            broadcast: 'Broadcast tx',
            explorer: 'Explorer',
            logout: 'Logout',
            settings: 'Settings'
        },
        validations: {
            required: 'Field cannot be empty',
            invalidAmount: 'Invalid amount',
            invalidAddress: 'Invalid address',
            insufficientFunds: 'Insufficient funds',
            invalidSeedPhrase: 'Invalid seed-phrase'
        },
        views: {
            login: {
                title: 'Welocome to <br/> TechnoGold Dashboard.',
                description: 'Here you can do everything with your assets',
                createWallet: 'Create wallet',
                generateSeedPhrase: 'Generate seed phrase',
                generatedAddress: 'Generated address',
                generatedSeedPhrase: 'Generated seed phrase',
                loginWithSeedPhrase: 'Sign in with seed phrase',
                yourSeedPhrase: 'Your seed phrase',
                signIn: 'Sign in',
                agreements: 'By signin up, you agree to our',
            },
            wallet: {
                wallet: 'Wallet',
                walletAddress: 'Wallet address',
                balance: 'Balance',
                sendCoins: 'Send coins',
                sendButton: 'Send',
                recipient: 'Recipient',
                recipientPlaceholder: 'Recipient address',
                addMemoButton: 'Add memo',
                memoLabel: 'Memo',
                memoPlaceholder: 'Add memo...',
                amountLabel: 'Amount',
                amountPlaceholder: 'Amount...',
                memoPlaceholder: 'Message',
                notificationSuccessTransfer: 'Assets are transfered successfully!',
                updatedBalanceTimer: (ctx) => `Last updated ${ctx.named('time')} seconds ago`,
                reset: 'Reset',
                sendTransaction: 'Send transaction',
                gasFee: 'Gas fee',
                transferFee: 'Transfer fee',
                transactions: 'Transactions',
                showAllTransactions: 'Show all transactions',
                status: 'Status',
                addressDetails: 'Address',
                hash: 'Hash',
                transactionsHistory: {
                    sent: 'Sent',
                    recieved: 'Recieved',
                    minted: 'Minted',
                    burnt: 'Burnt',
                    barsDeposited: 'Bars deposited',
                    barsWithdrawn: 'Bars withdrawn',
                    rewardWithdrawn: 'Reward withdrawn',
                    amountDelegated: 'Amount delegated',
                    failed: 'Failed'
                }
            },
            bars: {
                createBar: {
                    title: 'Deposit bar',
                    barNo: 'Bar No.',
                    noOfPieces: 'No. of Pieces',
                    brandMark: 'Brand Mark',
                    barWeight: 'Bar weight (in Grams)',
                    barFineWeight: 'Fine weight (in Grams)',
                    certificateUrl: 'Certificate URL',
                    deposit: 'Deposit',
                    reset: 'Reset',
                    validations: {
                        barNo: 'Invalid gold bar number entered',
                        noOfPieces: 'Incorrect amount of gold bars entered',
                        brandMark: 'This field is required',
                        barWeight: 'Incorrect gold bar weight entered',
                        barFineWeight: 'Incorrect net weight of gold bar entered'
                    }
                },
                barsList: {
                    title: 'Deposited gold bars',
                    depository: 'Depository',
                    barNo: 'Bar No.',
                    brandMark: 'Brand Mark',
                    noOfPieces: 'No. of Pieces',
                    barWeight: 'Bar weight',
                    barFineWeight: 'Fine weight',
                    inGrams: '(in Grams)',
                    certUrlActions: 'Cert Url / Actions',
                    certificate: 'Certificate',
                    withdraw: 'Withdraw'
                },
                barItem: {
                    certificate: 'Certificate',
                    withdraw: 'Withdraw',
                    withdrawConfirmation: 'Do you really want to withdraw this gold bar?'
                },
                notificationSuccessBarDeposited: 'The gold bar has been successfully deposited!',
                notificationSuccessBarWithdrawed: 'The gold bar has been successfully withdrawn!',
            },
            delegation: {
                delegate: {
                    title: 'Delegate tokens',
                    delegateTo: 'Delegate to',
                    amount: 'Amount',
                    availableBalance: 'Available balance',
                    fee: 'Fee',
                    reset: 'Reset',
                    delegate: 'Delegate'
                },
                reward: {
                    title: "Reward amount",
                    fee: 'Fee',
                    claim: 'Claim'
                },
                list: {
                    title: 'Delegated stakes',
                    validator: 'Validator',
                    amount: 'Amount',
                    unbonding: 'Unbond amount',
                    redelegate: 'Redelegate',
                    unbond: 'Unbond'
                },
                notifications: {
                    successfulRedelegated: 'Operation was successfully completed',
                    successfulUnbonded: 'Operation was successfully completed'
                },
                notificationSuccessDelegate: 'Coins have been successfully delegated!',
                notificationSuccessReward: 'Reward successfully received!'
            },
            emission: {
                mint: {
                    title: 'Mint coins',
                    amount: 'Amount',
                    totalReserve: 'Reserve',
                    totalSupply: 'Total supply',
                    fee: 'Fee',
                    reset: 'Reset',
                    mint: 'Mint'
                },
                burn: {
                    title: 'Burn coins',
                    amount: 'Amount',
                    totalSupply: 'Total supply',
                    fee: 'Fee',
                    reset: 'Reset',
                    burn: 'Burn',
                    balance: 'Balance'
                },
                notificationSuccessMinted: 'Coins are minted!',
                notificationSuccessBurnt: 'Coins are burnt!'
            },
            proposals: {
                create: {
                    title: 'Proposal to change the depository operator',
                    name: 'Proposal title',
                    description: 'Proposal description',
                    operator: 'Operator',
                    deposit: 'Deposit',
                    fee: 'Fee',
                    reset: 'Reset',
                    create: 'Create'
                },
                list: {
                    title: 'Proposals',
                    id: 'Proposal ID',
                    name: 'Title',
                    proposer: 'Proposer',
                    deposit: 'Deposit',
                    vote: 'Vote',
                    depositLabel: 'Deposit',
                    voteYes: 'Yes',
                    voteNo: 'No',
                    voteAbstain: 'Abstain',
                    voteVeto: 'Veto',
                    showAllProposals: 'Show all proposals'
                },
                notificationSuccessProposalCreate: 'Proposal created!',
                notificationSuccessProposalDeposited: 'Proposal deposit has been made!!',
                notificationSuccessProposalVote: 'Your vote has been accepted!',
            },
            settings: {
                title: 'Settings',
                darkTheme: 'Dark theme'
            }
        }
    },
    ru: {
        common: {
            copied: 'Скопировано!',
            errorNotification: 'Произошла непредвиденная ошибка!',
            fee: 'Комиссия'
        },
        title: {
            home: 'TechnoGold - Панель управления'
        },
        menu: {
            wallet: 'Кошелек',
            bars: 'Слитки',
            emission: 'Эмиссия',
            delegation: 'Делегирование',
            proposals: 'Голосования',
            broadcast: 'Транслировать транзакцию',
            explorer: 'Обозреватель блоков',
            logout: 'Выйти',
            settings: 'Настройки'
        },
        validations: {
            required: 'Поле не может быть пустым',
            invalidAmount: 'Недопустимая сумма',
            invalidAddress: 'Неверный адрес',
            insufficientFunds: 'Недостаточно средств',
            invalidSeedPhrase: 'Неверная seed-фраза'
        },
        views: {
            login: {
                title: 'Добро пожаловать в <br/> панель управления TechnoGold.',
                description: 'Здесь вы можете делать все со своими активами',
                createWallet: 'Создать кошелек',
                generateSeedPhrase: 'Сгенерировать seed‑фразу',
                generatedAddress: 'Сгенерированный адрес',
                generatedSeedPhrase: 'Сгенерированная seed‑фраза',
                loginWithSeedPhrase: 'Войти при помощи seed-фразы',
                yourSeedPhrase: 'Ваша seed‑фраза',
                signIn: 'Войти',
                agreements: 'Регистрируясь, вы соглашаетесь с нашими',
            },
            wallet: {
                wallet: 'Кошелек',
                walletAddress: 'Адрес кошелька',
                balance: 'Баланс',
                sendCoins: 'Отправка монет',
                sendButton: 'Отправить',
                recipient: 'Получатель',
                recipientPlaceholder: 'Адрес получателя',
                addMemoButton: 'Добавить сообщение',
                memoLabel: 'Сообщение',
                memoPlaceholder: 'Добавить сообщение...',
                amountLabel: 'Сумма',
                amountPlaceholder: 'Сумма перевода...',
                memoPlaceholder: 'Сообщение',
                notificationSuccessTransfer: 'Средства успешно переведены!',
                updatedBalanceTimer: (ctx) => `Обновлено ${ctx.named('time')} секунд назад`,
                reset: 'Сбросить',
                sendTransaction: 'Отправить',
                gasFee: 'Комиссия за газ',
                transferFee: 'Комиссия за перевод',
                transactions: 'Транзакции',
                showAllTransactions: 'Посмотреть все транзакции',
                status: 'Статус',
                addressDetails: 'Адрес',
                hash: 'Хэш',
                transactionsHistory: {
                    sent: 'Отправлено',
                    recieved: 'Получено',
                    minted: 'Выпущено',
                    burnt: 'Сожжено',
                    barsDeposited: 'Слиток депонирован',
                    barsWithdrawn: 'Слиток снят с депонирования',
                    rewardWithdrawn: 'Вознаграждение получно',
                    amountDelegated: 'Делегировано',
                    failed: 'Неудачно'
                }
            },
            bars: {
                createBar: {
                    title: 'Принять слиток на хранение',
                    barNo: 'Номер слитка',
                    noOfPieces: 'Количество',
                    brandMark: 'Бренд',
                    barWeight: 'Вес слитка (в граммах)',
                    barFineWeight: 'Чистый вес слитка (в граммах)',
                    certificateUrl: 'URL сертификата',
                    deposit: 'Принять на хранение',
                    reset: 'Сбросить',
                    validations: {
                        barNo: 'Введен неверный номер слитка',
                        noOfPieces: 'Введено неверное количество слитков',
                        brandMark: 'Поле обязательно к заполнению',
                        barWeight: 'Введен неверный вес слитка',
                        barFineWeight: 'Введен неверный чистый вес слитка'
                    }
                },
                barsList: {
                    title: 'Депонированные золотые слитки',
                    depository: 'Депозиторий',
                    barNo: 'Номер слитка',
                    brandMark: 'Бренд',
                    noOfPieces: 'Количество',
                    barWeight: 'Вес слитка',
                    barFineWeight: 'Чистый вес',
                    inGrams: '(в граммах)',
                    certUrlActions: 'Сертификат / Действия'
                },
                barItem: {
                    certificate: 'Сертификат',
                    withdraw: 'Вывести',
                    withdrawConfirmation: 'Вы действительно хотите снять с депонирования этот золотой слиток?'
                },
                notificationSuccessBarDeposited: 'Слиток успешно принят на хранение!',
                notificationSuccessBarWithdrawed: 'Слиток успешно снят с хранения!',
            },
            delegation: {
                delegate: {
                    title: 'Делегирования монет',
                    delegateTo: 'Кому',
                    amount: 'Сумма',
                    availableBalance: 'Доступный баланс',
                    fee: 'Комиссия',
                    reset: 'Сбросить',
                    delegate: 'Делегировать'
                },
                reward: {
                    title: "Сумма вознаграждения",
                    fee: 'Комиссия',
                    claim: 'Вывести'
                },
                list: {
                    title: 'Делегированные стейки',
                    validator: 'Валидатор',
                    amount: 'Сумма',
                    unbonding: 'Unbonding',
                    redelegate: 'Ределегировать',
                    unbond: 'Вывести'
                },
                notifications: {
                    successfulRedelegated: 'Операция прошла успешно',
                    successfulUnbonded: 'Операция прошла успешно'
                },
                notificationSuccessDelegate: 'Средства успешно делегированы!',
                notificationSuccessReward: 'Вознаграждение успешно получено!'
            },
            emission: {
                mint: {
                    title: 'Выпустить токены',
                    amount: 'Сумма',
                    totalReserve: 'В резерве',
                    totalSupply: 'Всего выпущено',
                    fee: 'Комиссия',
                    reset: 'Сбросить',
                    mint: 'Выпустить'
                },
                burn: {
                    title: 'Перенести монеты в резерв',
                    amount: 'Сумма',
                    totalSupply: 'Всего выпущено',
                    fee: 'Комиссия',
                    reset: 'Сбросить',
                    burn: 'В резерв',
                    balance: 'Баланс'
                },
                notificationSuccessMinted: 'Монеты выпущены в оборот!',
                notificationSuccessBurnt: 'Монеты выведены в резерв!'
            },
            proposals: {
                create: {
                    title: 'Предложение по смене оператора депозитария',
                    name: 'Название предложения',
                    description: 'Описание предложения',
                    operator: 'Оператор',
                    deposit: 'Депозит',
                    fee: 'Комиссия',
                    reset: 'Сбросить',
                    create: 'Создать'
                },
                list: {
                    title: 'Предложения',
                    id: 'ID предложения',
                    name: 'Название',
                    proposer: 'Автор',
                    deposit: 'Пополнить депозит',
                    vote: 'Проголосовать',
                    depositLabel: 'Депозит',
                    voteYes: 'За',
                    voteNo: 'Против',
                    voteAbstain: 'Воздержаться',
                    voteVeto: 'Вето',
                    showAllProposals: 'Посмотреть все предложения'
                },
                notificationSuccessProposalCreate: 'Предложение принято!',
                notificationSuccessProposalDeposited: 'Внесен депозит по предложению!',
                notificationSuccessProposalVote: 'Ваш голос принят!',
            },
            settings: {
                title: 'Настройки',
                darkTheme: 'Темная тема'
            }
        }
    }
}

export default messages